import { useContextSafely } from 'hooks/useContextSafely';
import React, {
  type ReactElement,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { ToastProps } from '@fragment/ui/src/components/Toast/Toast';

type ToastManagerContextType = {
  showToast: (toast: ReactElement) => void;
  dismissCurrentToast: VoidFunction;
};

export const ToastManagerContext =
  createContext<ToastManagerContextType | null>(null);

export const useToastManager = () => {
  const [currentToast, setCurrentToast] = useState<ReactElement | null>(null);

  return useMemo(
    () => ({
      currentToast,
      showToast: (toast: ReactElement) =>
        setCurrentToast((prev) => prev || toast),
      dismissCurrentToast: () => setCurrentToast(null),
    }),
    [currentToast]
  );
};

export const useToast = () => useContextSafely(ToastManagerContext);

type Props = {
  children: ReactElement<ToastProps>;
  duration?: number;
};

const DEFAULT_TOAST_DURATION_MS = 6000;

export const ToastRoot = ({
  children,
  duration = DEFAULT_TOAST_DURATION_MS,
}: Props) => {
  const { dismissCurrentToast } = useContextSafely(ToastManagerContext);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    timeoutId = setTimeout(() => {
      timeoutId = null;
      if (!children.props?.persistent) {
        dismissCurrentToast();
      }
    }, duration);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [duration, dismissCurrentToast, children.props?.persistent]);

  return <div className="absolute top-f2 right-f2 z-50">{children}</div>;
};
