import type { ReactNode } from 'react';
import type { CombinedError, UseQueryExecute } from 'urql';
import React, { createContext, useMemo } from 'react';

import type { GetSchemaQuery } from '../hooks/customerApi';
import { useGetSchemaQuery } from '../hooks/customerApi';
import { useCustomerApiContext } from '../hooks/useCustomerApiContext';

type Props = {
  children: ReactNode;
  schemaKey: string;
};

type Ledger = NonNullable<
  NonNullable<NonNullable<GetSchemaQuery['schema']>['ledgers']>['nodes']
>[number];
type SchemaVersion = NonNullable<
  NonNullable<NonNullable<GetSchemaQuery['schema']>['versions']>['nodes']
>[number];

type SchemaMetadataCtx = {
  ledgers: Ledger[];
  versions: SchemaVersion[];
  fetching: boolean;
  error: CombinedError | undefined;
  refreshMetadata: UseQueryExecute;
  schemaKey: string;
};

const defaultContext = {
  ledgers: [],
  fetching: false,
  error: undefined,
  versions: [],
  schemaKey: '',
  refreshMetadata: () => {},
};

export const SchemaMetadataContext =
  createContext<SchemaMetadataCtx>(defaultContext);

export const SchemaMetadataProvider = ({ children, schemaKey }: Props) => {
  const { context: customerApiCtx, token } = useCustomerApiContext();
  const variables = useMemo(
    () => ({
      key: schemaKey,
    }),
    [schemaKey]
  );
  const [{ data: schemaVersions, fetching, error }, refetch] =
    useGetSchemaQuery({
      variables,
      context: customerApiCtx,
      pause: !token,
    });

  const context = useMemo(
    () => ({
      ledgers: schemaVersions?.schema?.ledgers?.nodes ?? [],
      versions: schemaVersions?.schema?.versions.nodes ?? [],
      fetching,
      error,
      refreshMetadata: refetch,
      schemaKey,
    }),
    [
      schemaVersions?.schema?.ledgers?.nodes,
      schemaVersions?.schema?.versions.nodes,
      fetching,
      error,
      refetch,
      schemaKey,
    ]
  );

  return (
    <SchemaMetadataContext.Provider value={context}>
      {children}
    </SchemaMetadataContext.Provider>
  );
};
