import React from 'react';

import { useLoadingText } from '../../hooks/LoadingText';
import { Text } from '../Text';

type Props = {
  text: string;
};

export const LoadingText = ({ text }: Props) => {
  const displayValue = useLoadingText(text);
  return (
    <span
      style={{
        fontVariantLigatures: 'no-contextual',
      }}
    >
      <Text>{displayValue}</Text>
    </span>
  );
};
