import React, { type ReactNode } from 'react';

import type { TWFontSizeProp } from '../../types/tailwind';
import { Text } from '../Text';

export type ErrorTextProps<FontSizeProp> = {
  children: ReactNode;
  fontSize?: TWFontSizeProp<FontSizeProp>;
};

export const ErrorText = <FontSizeProp,>({
  fontSize,
  children,
}: ErrorTextProps<FontSizeProp>) => (
  <Text fontSize={fontSize}>
    <Text color="text-orange">!</Text>
    &nbsp;{children}
  </Text>
);
