import { Routes } from 'components/routing/Routes';
import { TopLevelLoader } from 'components/TopLevelLoader';
import React, { Suspense } from 'react';
import { AuthProvider } from 'wrappers/AuthProvider';
import { FeatureGateProvider } from 'wrappers/FeatureGateProvider';
import { GlobalApiAccessTokenProvider } from 'wrappers/GlobalApiAccessTokenProvider';
import { GlobalErrorBoundary } from 'wrappers/GlobalErrorBoundary';
import { IdleTimerContainer } from 'wrappers/IdleTimerContainer/IdleTimerContainer';
import { PodApiAccessTokenProvider } from 'wrappers/PodApiAccessTokenProvider';
import { UrqlProvider } from 'wrappers/urqlProvider/UrqlProvider';
import { UserInfoProvider } from 'wrappers/UserInfoProvider';
import { WorkspaceProvider } from 'wrappers/WorkspaceProvider';

export const App = () => (
  <FeatureGateProvider>
    <GlobalErrorBoundary>
      <AuthProvider>
        <Suspense fallback={<TopLevelLoader />}>
          <GlobalApiAccessTokenProvider>
            <UrqlProvider>
              <UserInfoProvider>
                <WorkspaceProvider>
                  <PodApiAccessTokenProvider>
                    <IdleTimerContainer>
                      <Suspense fallback={<TopLevelLoader />}>
                        <Routes />
                      </Suspense>
                    </IdleTimerContainer>
                  </PodApiAccessTokenProvider>
                </WorkspaceProvider>
              </UserInfoProvider>
            </UrqlProvider>
          </GlobalApiAccessTokenProvider>
        </Suspense>
      </AuthProvider>
    </GlobalErrorBoundary>
  </FeatureGateProvider>
);
