import { createContext } from 'react';

export type ColorMode = 'light' | 'dark' | 'system';

export type ColorModeContextValue = {
  mode: ColorMode;
  setMode: (mode: ColorMode) => void;
};

export const ColorModeContext = createContext<ColorModeContextValue>({
  mode: 'system',
  setMode: () => {},
});
