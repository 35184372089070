import type { UrqlOperationContext } from 'lib/urqlClient';
import assert from 'assert';
import React from 'react';
import { usePodApiAccessTokenContext } from 'wrappers/PodApiAccessTokenProvider';

import { useWorkspace } from './useWorkspace';

export function useCustomerApiContext() {
  const { workspace } = useWorkspace();
  const podApiAccessTokens = usePodApiAccessTokenContext();

  assert(workspace, 'Workspace pod info not found');
  const url = workspace.pod.dashboardCustomerApiUrl;

  const context: UrqlOperationContext = React.useMemo(
    () => ({
      token: podApiAccessTokens.customerApiToken,
      url,
      workspaceId: workspace.id,
    }),
    [podApiAccessTokens.customerApiToken, url, workspace.id]
  );

  return {
    context,
    token: podApiAccessTokens.customerApiToken,
  };
}
