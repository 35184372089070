import { cacheExchange, createClient, fetchExchange } from 'urql';
import type { Client, OperationContext } from '@urql/core';
import type { AuthConfig } from '@urql/exchange-auth';
import { makeOperation } from '@urql/core';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';

// Serve results from cache while fetching updated data from the API.
// This allows users to use the API explorer for writes, then see the results.
let requestPolicy: OperationContext['requestPolicy'] = 'cache-and-network';
if (window.Cypress) {
  // In cypress tests, all requests go to mocked URLs

  // cache-and-network can unpredictably trigger re-requests and re-renders
  // as such it makes tests unreliable. Use cache-first for cypress instead.
  requestPolicy = 'cache-first';
}

/*
 * urql auth exchange handlers
 */
type FragmentAuthConfig = AuthConfig;

export type UrqlOperationContext = {
  url: string;
  token?: string | null;
  workspaceId?: string;
};

const addAuthToOperation: FragmentAuthConfig['addAuthToOperation'] = (
  operation
) => {
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  const { context } = operation as unknown as { context: UrqlOperationContext };
  const extraHeaders: Record<string, string> = {};
  if (context.workspaceId) {
    extraHeaders['x-fragment-workspace'] = context.workspaceId;
  }
  if (context.token) {
    return makeOperation(operation.kind, operation, {
      ...operation.context,
      url: context.url,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          ...extraHeaders,
          Authorization: `Bearer ${context.token}`,
        },
      },
    });
  }

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    url: context.url,
  });
};

// TODO: test if this works
const didAuthError: FragmentAuthConfig['didAuthError'] = (error) =>
  error.response?.status === 401;

// for the internal API is available
const refreshAuth: FragmentAuthConfig['refreshAuth'] = async () => {};

const makeClient = (): Client =>
  createClient({
    url: 'ERROR.Context-not-provided-to-urql-client.Using-default-url',
    suspense: true,
    exchanges: [
      ...(process.env.NODE_ENV === 'development' ? [devtoolsExchange] : []),
      cacheExchange,
      authExchange(async () => ({
        refreshAuth,
        addAuthToOperation,
        didAuthError,
      })),
      fetchExchange,
    ],
    requestPolicy,
  });

export { makeClient };
