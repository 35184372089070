import type { FC, PropsWithChildren } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import type { ColorMode } from './ColorModeContext';
import { ColorModeContext } from './ColorModeContext';

// Also in pages/_document.tsx
const LOCAL_STORAGE_KEY = 'fragment-dev-color-mode';

export const ColorModeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<ColorMode>('system');
  const [retrievedFromLocalStorage, setRetrievedFromLocalStorage] =
    useState<boolean>(false);

  // Adjust system preference based off user's system
  const systemIsDark = useMediaQuery({
    query: '(prefers-color-scheme: dark)',
  });

  useEffect(() => {
    // Manage local storage
    if (typeof window !== 'undefined') {
      // Only retrieve from local storage once
      if (!retrievedFromLocalStorage) {
        const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (stored === 'light' || stored === 'dark' || stored === 'system') {
          setMode(stored);
        }
        setRetrievedFromLocalStorage(true);
      }
      if (retrievedFromLocalStorage) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, mode);
        // Adjust DOM based off mode and systemPreference
        if (mode === 'light' || (mode === 'system' && !systemIsDark)) {
          document.documentElement.classList.remove('dark');
          document.documentElement.classList.add('light');
        }
        if (mode === 'dark' || (mode === 'system' && systemIsDark)) {
          document.documentElement.classList.remove('light');
          document.documentElement.classList.add('dark');
        }
      }
    }
  }, [mode, systemIsDark, retrievedFromLocalStorage]);

  const contextValue = useMemo(
    () => ({
      mode,
      setMode,
    }),
    [mode, setMode]
  );

  return (
    <ColorModeContext.Provider value={contextValue}>
      {children}
    </ColorModeContext.Provider>
  );
};
