import clsx from 'clsx';
import React, {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from 'react';
import { Link as WouterLink, useLocation } from 'wouter';

import { Icon } from '@fragment/ui/src/components/Icon';
import { Text } from '@fragment/ui/src/components/Text';

type Props = {
  position: 'left' | 'right';
  size?: 'small' | 'large';
  closable?: boolean;
} & (
  | {
      trigger: 'action';
      href?: never;
      onClose: (e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
    }
  | { trigger: 'navigate'; href: string; onClose?: never }
);

const sharedDrawerStyles =
  'flex flex-col bg-canvas border-main-400 h-[inherit] overflow-y-auto';

export const Drawer = ({
  position,
  size = 'small',
  closable = true,
  children,
  trigger,
  onClose,
  href,
}: PropsWithChildren<Props>) => {
  const [, setLocation] = useLocation();

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (trigger === 'action' && e.key === 'Escape' && closable) {
        onClose(e);
      } else if (trigger === 'navigate' && e.key === 'Escape' && closable) {
        setLocation(href);
      }
    };
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [trigger, setLocation, href, onClose, closable]);

  const wrap = (node: ReactNode) =>
    trigger === 'action' ? (
      <Text as="button" onClick={onClose} extraClassNames="z-50">
        {node}
      </Text>
    ) : (
      <WouterLink href={href}>
        <Text as="a">{node}</Text>
      </WouterLink>
    );

  return (
    <div
      className={clsx([
        'fixed top-f0 h-full z-40',
        position === 'left' && 'left-f0',
        position === 'right' && 'right-f0',
      ])}
    >
      {position === 'left' && (
        <div
          className={clsx(
            sharedDrawerStyles,
            'border-r pl-f2 tablet:pl-f4 py-f2 tablet:py-f4 min-w-[240px]',
            'relative'
          )}
        >
          {closable &&
            wrap(
              <Text
                as="div"
                textAlign="text-right"
                position="absolute"
                placement="top-[23px] tablet:top-[41px] left-f2 tablet:left-f4"
              >
                <Icon type="hamburger" />
              </Text>
            )}
          {children}
        </div>
      )}
      {position === 'right' && (
        <div
          data-testid="right-drawer"
          className={clsx(
            sharedDrawerStyles,
            'border-l pt-f14 pb-f4',
            size === 'small' && 'px-f2 w-[329px]',
            size === 'large' && 'px-f4 w-[504px]',
            'relative'
          )}
        >
          {closable &&
            wrap(
              <Text
                as="div"
                textAlign="text-right"
                position="absolute"
                placement="top-f4 right-f2"
              >
                <Icon type="close" />
              </Text>
            )}
          {children}
        </div>
      )}
    </div>
  );
};
