import React from 'react';
import { Text } from '../Text';
import type { ListItemProps } from './types';

export const ListItem = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>({
  ...textEngineProps
}: ListItemProps<
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>) => (
  <Text
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...textEngineProps}
    as="li"
  />
);
