import type { ActivePath } from 'components/SideNav';
import type { DefaultParams, RouteProps } from 'wouter';
import { SidebarLayoutSuspense } from 'components/SidebarLayoutSuspense/SidebarLayoutSuspense';
import React from 'react';

import { DecodedParamsRoute } from './DecodedParamsRoute';

type Props<T extends DefaultParams> = RouteProps<T> & {
  activePath: ActivePath;
  loadingText: string;
};

export const DecodedParamsRouteSuspense = <P extends DefaultParams>(
  props: Props<P>
) => {
  const { loadingText, activePath, ...rest } = props;
  return (
    <SidebarLayoutSuspense activePath={activePath} loadingText={loadingText}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DecodedParamsRoute {...rest} />
    </SidebarLayoutSuspense>
  );
};
