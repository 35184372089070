import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { sizeToSvgWidth } from '../../utils';

export const IncreaseLink: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  'data-testid': testId = 'linkbadge',
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="12"
      height="12"
      transform="translate(0.340088)"
      fill="url(#paint0_linear_1_130)"
    />
    <path
      d="M8.65583 5.82998L7.18213 3.99539V6.56541L8.65583 8.39999V5.82998Z"
      fill="url(#paint1_linear_1_130)"
    />
    <path
      d="M10.3401 3.60001H8.65589V8.40001H10.3401V3.60001ZM5.49799 3.99541H7.18219V8.40001H5.49799V3.99541ZM2.34009 4.39078H4.02429V8.40001H2.34009V4.39078Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_130"
        x1="12"
        y1="0"
        x2="6.77351e-08"
        y2="11.4317"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36DBE1" />
        <stop offset="1" stopColor="#6FFB90" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_130"
        x1="7.18213"
        y1="3.99539"
        x2="8.65583"
        y2="3.99539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2F2F2" stopOpacity="0.91" />
        <stop offset="0.552083" stopColor="white" stopOpacity="0.75" />
        <stop offset="1" stopColor="#F2F2F2" stopOpacity="0.92" />
      </linearGradient>
    </defs>
  </svg>
);
