import clsx from 'clsx';
import { useUserInfo } from 'hooks/useUserInfo';
import React from 'react';
import { Menu } from '@headlessui/react';

import { Text } from '@fragment/ui/src/components/Text';

import { LogoutButton } from './LogoutButton/LogoutButtonV2';

export const Profile = () => {
  const user = useUserInfo();
  return (
    <div className="relative flex items-center">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              data-test="profile"
              className={clsx(
                open ? 'text-main-600' : 'text-main',
                'hover:text-main-600 transition-colors'
              )}
            >
              Profile
            </Menu.Button>
            <Menu.Items className="absolute w-full bottom-f3 -left-f2 border-[1px] border-main-400">
              <Menu.Item>
                {({ active }) => <LogoutButton active={active} />}
              </Menu.Item>
              <Menu.Item disabled>
                <Text
                  as="div"
                  background="bg-canvas"
                  color="text-main hover:text-main-600"
                  padding="py-f1 px-f2"
                  cursor="cursor-default"
                  truncate
                  title={user.data?.email || undefined}
                >
                  {user.data?.email || 'Email not found'}
                </Text>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};
