import React from 'react';

import type { ColorType, IconSizeType, TextSizeType } from '../../utils';
import { Text } from '../../Text';
import {
  colorToHexCode,
  colorToTextClassName,
  sizeToSvgWidth,
  sizeToTextClassName,
} from '../../utils';
import {
  AddLink,
  Asset,
  CustomSyncLink,
  Empty,
  Expense,
  ExternalAccount,
  Income,
  IncreaseLink,
  LeftTriangle,
  Liability,
  StripeLink,
  UnitLink,
} from '../svg';
import { AlertIcon } from '../svg/Alert';
import { AssetLine } from '../svg/AssetLine';
import { EntryGroup } from '../svg/EntryGroup';
import { ErrorToastIcon } from '../svg/ErrorToastIcon';
import { ExpenseLine } from '../svg/ExpenseLine';
import { Hamburger } from '../svg/Hamburger';
import { IncomeLine } from '../svg/IncomeLine';
import { LiabilityLine } from '../svg/LiabilityLine';
import { ReconciledTx } from '../svg/ReconciledTx';
import { Sidebar } from '../svg/Sidebar';
import { SuccessToastIcon } from '../svg/SuccessToastIcon';
import { Trash } from '../svg/Trash';
import { UnreconciledTx } from '../svg/UnreconciledTx';

export const staticIconTypes = {
  external: '\u2197',
  edit: '\u2194',
  select: '\u2195',
  bullet: '\u2022',
  check: '\u2713',
  copy: '\u2398',
  retry: '\u2192',
  back: '\u2190',
  up: '\u2191',
  rotate: '\u21BA',
  down: '\u2193',
  right: '\u2192',
  left: '\u2190',
  close: '\u2715',
  scale: '\u2696',
  stop: '\u25A0',
  play: '\u25B6',
} as const;

export type StaticIconType = keyof typeof staticIconTypes;

export const svgIconTypes = {
  unreconciledTx: UnreconciledTx,
  reconciledTx: ReconciledTx,
  success: SuccessToastIcon,
  error: ErrorToastIcon,
  alert: AlertIcon,
  externalAccount: ExternalAccount,
  asset: Asset,
  liability: Liability,
  expense: Expense,
  income: Income,
  empty: Empty,
  increaseLink: IncreaseLink,
  stripeLink: StripeLink,
  unitLink: UnitLink,
  customSyncLink: CustomSyncLink,
  addLink: AddLink,
  hamburger: Hamburger,
  assetLine: AssetLine,
  leftTriangle: LeftTriangle,
  incomeLine: IncomeLine,
  liabilityLine: LiabilityLine,
  expenseLine: ExpenseLine,
  sidebar: Sidebar,
  trash: Trash,
  entryGroup: EntryGroup,
};

export type SvgIconType = keyof typeof svgIconTypes;

type IconProps = (
  | {
      type: StaticIconType;
      size?: TextSizeType;
    }
  | {
      type: SvgIconType;
      size?: IconSizeType;
    }
) & {
  'data-testid'?: string;
  backgroundColor?: ColorType;
  primaryColor?: ColorType;
  includeSecondaryMark?: boolean;
};

const isStaticIconType = (
  type: StaticIconType | SvgIconType
): type is StaticIconType => type in staticIconTypes;

export const Icon = ({
  type,
  size,
  backgroundColor,
  primaryColor,
  includeSecondaryMark,
  'data-testid': testId,
}: IconProps): JSX.Element => {
  if (isStaticIconType(type)) {
    return (
      <Text
        fontSize={
          sizeToTextClassName[(size as TextSizeType | undefined) ?? 'fnormal']
        }
        color={primaryColor && colorToTextClassName[primaryColor]}
        // Explicitly setting the inherited props so that they won't inherit.
        // For example, I don't think we want our glyphs to automatically be the same font
        // size as the button since glyph font size is based on what looks visually balanced
        // next to the text. I think ideally glyphs should be svgs so we don't need to worry
        // about this
        fontWeight="font-normal"
        fontStyle="not-italic"
        fontDecoration="no-underline"
        lineHeight="leading-none"
        data-testid={testId}
      >
        {staticIconTypes[type]}
      </Text>
    );
  }
  const SvgComponent = svgIconTypes[type];
  return (
    <SvgComponent
      data-testid={testId}
      width={size && sizeToSvgWidth[size]}
      backgroundColorHexCode={
        backgroundColor && colorToHexCode[backgroundColor]
      }
      primaryColorHexCode={primaryColor && colorToHexCode[primaryColor]}
      includeSecondaryMark={includeSecondaryMark}
    />
  );
};
