import type { UrqlOperationContext } from 'lib/urqlClient';
import assert from 'assert';
import React from 'react';
import { usePodApiAccessTokenContext } from 'wrappers/PodApiAccessTokenProvider';

import { useWorkspace } from './useWorkspace';

export function useInternalApiContext() {
  const ctx = usePodApiAccessTokenContext();
  const { workspace } = useWorkspace();
  const { internalApiToken } = ctx;

  assert(workspace, 'Workspace pod info not found');
  const url = workspace.pod.internalApiUrl;

  const context: UrqlOperationContext = React.useMemo(
    () => ({ url, token: internalApiToken }),
    [internalApiToken, url]
  );

  return {
    context,
    token: internalApiToken,
  };
}
