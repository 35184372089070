import type { DefaultParams, Params } from 'wouter';

export const decodeParams = <T extends DefaultParams>(
  params: Params<T>
): Params<T> =>
  Object.keys(params).reduce((acc, paramName) => {
    acc[paramName] = decodeURIComponent(params[paramName]);
    return acc;
  }, {} as Record<string, string>) as T;

export const decodeRouteAndRender = <T extends DefaultParams>(
  params: T,
  render: (value: T) => JSX.Element
) => {
  const decodedParams = Object.entries(params).reduce(
    (acc: DefaultParams, [param, value]) => {
      acc[param] = decodeURIComponent(value);
      return acc;
    },
    {}
  );
  return render(decodedParams as T);
};

export const makePath = (
  components: string[],
  trailingSlash = false,
  searchParams?: URLSearchParams,
  /**
   * If true, the path will be prefixed with a tilde (~) to indicate that it is an absoltue route.
   * Otherwise, the path will be matched against the current router.
   * Use this if you want to route to a path that's under a different router.
   */
  absolute?: boolean
) => {
  const encoded = components.map(encodeURIComponent).join('/');
  const path = trailingSlash && !searchParams ? `/${encoded}/` : `/${encoded}`;
  const url = `${path}${searchParams ? `?${searchParams.toString()}` : ''}`;
  return absolute ? `~${url}` : url;
};
