import clsx from 'clsx';
import { useWorkspace } from 'hooks/useWorkspace';
import React from 'react';
import { Link as WouterLink } from 'wouter';
import { Menu } from '@headlessui/react';

import { Icon } from '@fragment/ui/src/components/Icon';
import { Text } from '@fragment/ui/src/components/Text';

import { useIsBeta } from '../../hooks/useIsBeta';
import { useUserInfo } from '../../hooks/useUserInfo';
import { LogoutButton } from '../Profile/LogoutButton/LogoutButtonV2';

type Props = {
  className?: string;
};

const getBgColor = ({
  active,
  disabled,
}: {
  active: boolean;
  disabled: boolean;
}) => {
  const bgColor = active && !disabled ? 'bg-canvas' : 'bg-transparent';
  const bgHoverColor = disabled ? '' : ' hover:bg-negative';
  return `${bgColor}${bgHoverColor}` as const;
};

export const WorkspaceSwitcher = ({ className }: Props) => {
  const {
    workspace: currentWorkspace,
    buildNewWorkspaceUrl,
    workspaces,
  } = useWorkspace();

  const { data: userInfo } = useUserInfo();
  const isBeta = useIsBeta();

  return (
    <div className={clsx('relative min-w-f0 w-full', className)}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button data-testid="current-display-ws">
              <Text
                display="flex"
                flexContainer="items-center"
                maxW="max-w-f18"
                fontWeight="font-normal"
                transition="transition-colors"
                color={open ? 'text-main' : 'text-main-500 hover:text-main'}
              >
                <Text as="div" truncate>
                  {currentWorkspace?.name || 'Select a Workspace'}
                </Text>
                <div>
                  &nbsp;
                  <Icon type="select" />
                </div>
              </Text>
            </Menu.Button>
            <Menu.Items
              className={clsx(
                isBeta ? 'top-f2' : 'top-f3',
                isBeta ? 'tablet:-left-f0' : 'tablet:-left-f2',
                isBeta
                  ? 'border border-t-0 border-main-200'
                  : 'border border-main-400',
                'bg-canvas absolute -left-f0 w-full max-h-f33 overflow-y-scroll z-20'
              )}
              data-testid="ws-dropdown"
            >
              {workspaces.map(({ id, name }) => {
                const workspaceActive = id === currentWorkspace?.id;
                return (
                  <Menu.Item
                    key={id}
                    data-testid={id}
                    disabled={workspaceActive}
                  >
                    {({ active }) => (
                      <WouterLink href={`~${buildNewWorkspaceUrl(id)}`}>
                        <Text
                          as="a"
                          title={name}
                          cursor={
                            workspaceActive
                              ? 'cursor-default'
                              : 'cursor-pointer'
                          }
                          color={
                            workspaceActive ? 'text-main-600' : 'text-main'
                          }
                          background={getBgColor({
                            active,
                            disabled: workspaceActive,
                          })}
                          transition="transition-colors"
                          display="block"
                          w="w-full"
                          h={isBeta ? 'h-f2' : 'h-f4'}
                          padding={isBeta ? 'p-f0' : 'py-f1 px-f2'}
                          fontWeight="font-light"
                          truncate
                        >
                          {name}
                        </Text>
                      </WouterLink>
                    )}
                  </Menu.Item>
                );
              })}
              <Menu.Item data-testid="new-ws">
                {({ active }) => (
                  <WouterLink href="~/add-new-workspace">
                    <Text
                      as="a"
                      color="text-main"
                      background={`${
                        active ? 'bg-canvas ' : ''
                      }hover:bg-negative`}
                      transition="transition-colors"
                      display="block"
                      w="w-full"
                      h={isBeta ? 'h-f2' : 'h-f4'}
                      padding={isBeta ? undefined : 'py-f1 px-f2'}
                      fontWeight="font-light"
                      truncate
                    >
                      New Workspace +
                    </Text>
                  </WouterLink>
                )}
              </Menu.Item>
              {isBeta && (
                <>
                  <Menu.Item>
                    <div className="h-f2" />
                  </Menu.Item>
                  <Menu.Item>
                    <Text
                      display="block"
                      color="text-main-600"
                      h="h-f2"
                      truncate
                    >
                      {userInfo?.email}
                    </Text>
                  </Menu.Item>
                  <Menu.Item>
                    <LogoutButton />
                  </Menu.Item>
                </>
              )}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};
