import React, { type PropsWithChildren } from 'react';

import { Text } from '@fragment/ui/src/components/Text';

import { useWorkspace } from '../WorkspaceProvider';

type Props = {
  className?: string;
};

export const WorkspaceRequired = ({
  children,
  className = '',
}: PropsWithChildren<Props>) => {
  const { workspace } = useWorkspace();

  if (!workspace) {
    return (
      <div className={className}>
        <Text as="p" fontSize="text-fnormal" margin="mb-f2">
          Access Denied
        </Text>
        <Text as="p" fontSize="text-fnormal" maxW="max-w-[409px]">
          The workspace you have requested either does not exist, or you do not
          have access to it.
        </Text>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
