import type { useFlags } from 'flagsmith/react';
import type { OnboardBnkDevEnv, StripeEnv } from 'hooks/internalApi';

export type LocalStorageValues = {
  post_login_redirect: string;
  user_redirect_href: {
    path: string;
    guest?: boolean;
  };
  default_workspace_id: {
    id: string;
  };
  increase_onboard_payload?: {
    env: OnboardBnkDevEnv;
    name: string;
    state: string;
    workspaceId: string;
  };
  stripe_onboard_payload?: {
    env: StripeEnv;
    name: string;
    state: string;
    workspaceId: string;
  };
  flagsmith_overrides?: Partial<ReturnType<typeof useFlags>>;
};

type Key = keyof LocalStorageValues;
export type GetLocalStorageReturnType<T extends Key> =
  | LocalStorageValues[T]
  | null;

// We namespace our local storage keys to avoid collisions.
const constructNamespacedKey = (key: string) => `fragment-local-storage:${key}`;

export const putLocalStorage = <T extends Key>(
  key: T,
  item: LocalStorageValues[T]
) => {
  const namespacedKey = constructNamespacedKey(key);
  const stringifiedItem = JSON.stringify(item);
  localStorage.setItem(namespacedKey, stringifiedItem);
};

export const getLocalStorage = <T extends Key>(key: T) => {
  const namespacedKey = constructNamespacedKey(key);
  const item = localStorage.getItem(namespacedKey);
  if (!item) {
    return null;
  }

  return JSON.parse(item) as GetLocalStorageReturnType<T>;
};

export const removeLocalStorage = <T extends Key>(key: T): void => {
  const namespacedKey = constructNamespacedKey(key);
  localStorage.removeItem(namespacedKey);
};
