import type { ActivePath } from 'components/SideNav';
import { Layout } from 'components/Layout';
import { SideBar } from 'components/Sidebar/Sidebar';
import React, { Suspense } from 'react';

import { LoadingText } from '@fragment/ui/src/components/LoadingText';

type Props = {
  loadingText: string;
  activePath: ActivePath;
  children: React.ReactNode;
};

export const SidebarLayoutSuspense = ({
  loadingText,
  activePath,
  children,
}: Props) => (
  <Suspense
    fallback={
      <Layout>
        <SideBar active={activePath} suspense />
        <div className="py-f1">
          <LoadingText text={loadingText} />
        </div>
      </Layout>
    }
  >
    {children}
  </Suspense>
);
