import type { PropsWithChildren } from 'react';
import { H } from 'highlight.run';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Icon } from '@fragment/ui/src/components/Icon';
import { Text } from '@fragment/ui/src/components/Text';

export const ErrorFallback = (): JSX.Element => (
  <div className="p-8 flex flex-col items-start gap-f8 text-fxl">
    <Text>Page load error</Text>
    <div className="flex flex-col items-start">
      <Text as="a" href={window.location.href}>
        Reload <Icon type="rotate" size="fxl" primaryColor="gray" />
      </Text>
      <Text
        as="a"
        color="text-main-600"
        href={
          // It's possible to end up in a loop here, e.g. if the user directly navigates
          // to a URL containing a nonexistent workspace (dashboard.fragment.dev/w/garbage/...)
          // This at least links them back to the main page in such a case.
          document.referrer !== window.location.href ? document.referrer : '/'
        }
      >
        Back to Dashboard <Icon type="right" size="fxl" primaryColor="gray" />
      </Text>
    </div>
  </div>
);

export const GlobalErrorBoundary = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const onError = useCallback((error: Error) => {
    H.consumeError(error);
  }, []);

  return (
    <ErrorBoundary onError={onError} FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};
