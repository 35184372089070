import React from 'react';

import { Text } from '@fragment/ui/src/components/Text';

export const GlobalApiAuthenticationFailed = () => (
  <div className="px-12 py-8 space-y-8 text-xs">
    <Text variant="title">Failed to authenticate user</Text>
    <Text as="p">This is most likely our fault.</Text>
  </div>
);

export const PodApiAuthenticationFailed = () => (
  <div className="px-12 py-8 space-y-8 text-xs">
    <Text variant="title">Failed to authenticate workspace</Text>
    <Text as="p">
      This is most likely our fault - in the meantime, you can try switching to
      other workspaces.
    </Text>
  </div>
);
