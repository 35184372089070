import React from 'react';

import { Text } from '../Text';

export const Logo = () => (
  <span
    style={{
      fontFeatureSettings: `'ss01' on`,
      fontVariantLigatures: 'no-contextual',
    }}
  >
    <Text as="span" fontSize="text-fxl">
      FRAGMENT
    </Text>
  </span>
);

export const InlineLogo = () => (
  <span
    style={{
      fontFeatureSettings: `'ss01' on`,
      fontVariantLigatures: 'no-contextual',
    }}
  >
    <Text as="span">FRAGMENT</Text>
  </span>
);
