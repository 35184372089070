import React from 'react';

import { Icon } from '@fragment/ui/src/components/Icon';
import { Text } from '@fragment/ui/src/components/Text';

export const WorkspaceNotFound = (): JSX.Element => (
  <div className="p-8 flex flex-col items-start gap-f8 text-fxl">
    <Text>Workspace Not Found</Text>
    <div className="flex flex-col items-start">
      <Text as="a" color="text-main-600" href="/">
        Back to Dashboard <Icon type="right" size="fxl" primaryColor="gray" />
      </Text>
    </div>
  </div>
);
