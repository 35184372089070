import { DefaultLoginRedirect } from 'components/login/DefaultLoginRedirect';
import { LoggedInPage } from 'components/login/LoggedInPage';
import { DefaultRedirect } from 'components/pages/DefaultRedirect';
import { LedgersRouter } from 'components/routing/LedgersRouter';
import { SidebarLayoutSuspense } from 'components/SidebarLayoutSuspense/SidebarLayoutSuspense';
import React, { lazy } from 'react';
import { putLocalStorage } from 'utils/localStorage';
import { makePath } from 'utils/routes';
import { Route, Switch } from 'wouter';
import { USER_LOGIN_REDIRECT } from 'wrappers/AuthProvider';
import { ProtectedRoute } from 'wrappers/ProtectedRoute';

import { ColorModeProvider } from '@fragment/ui/src/components/ColorMode/ColorModeProvider';

import { useIsBeta } from '../../hooks/useIsBeta';
import { DecodedParamsRoute } from './DecodedParamsRoute';

const NewWorkspacePage = lazy(async () => ({
  default: (await import('components/pages/NewWorkspacePage')).NewWorkspacePage,
}));
const UserInviteLoginPage = lazy(async () => ({
  default: (await import('components/pages/UserInviteLoginPage'))
    .UserInviteLoginPage,
}));
const AcceptInvitePage = lazy(async () => ({
  default: (await import('components/pages/AcceptInvitePage')).AcceptInvitePage,
}));
const ApiExplorerPage = lazy(async () => ({
  default: (await import('components/pages/ApiExplorerPage')).ApiExplorerPage,
}));
const WorkspaceInviteLoginPage = lazy(async () => ({
  default: (await import('components/pages/WorkspaceInviteLoginPage'))
    .WorkspaceInviteLoginPage,
}));
const IncreaseWorkspaceURLRedirect = lazy(async () => ({
  default: (
    await import('components/routing/redirects/IncreaseWorkspaceURLRedirect')
  ).IncreaseWorkspaceURLRedirect,
}));
const IncreaseOnboardingRedirect = lazy(async () => ({
  default: (
    await import('components/routing/redirects/OnboardIncreaseRedirect')
  ).OnboardIncreaseRedirect,
}));
const StripeWorkspaceURLRedirect = lazy(async () => ({
  default: (
    await import('components/routing/redirects/StripeWorkspaceURLRedirect')
  ).StripeWorkspaceURLRedirect,
}));
const StripeOnboardingRedirect = lazy(async () => ({
  default: (await import('components/routing/redirects/OnboardStripeRedirect'))
    .OnboardStripeRedirect,
}));
const DefaultTabRedirect = lazy(async () => ({
  default: (await import('components/routing/redirects/DefaultTabRedirect'))
    .DefaultTabRedirect,
}));
const FragmentIncIncomeStatementPage = lazy(async () => ({
  default: (await import('components/pages/FragmentIncIncomeStatementPage'))
    .FragmentIncIncomeStatementPage,
}));
const FragmentIncBalanceSheetPage = lazy(async () => ({
  default: (await import('components/pages/FragmentIncBalanceSheetPage'))
    .FragmentIncBalanceSheetPage,
}));

const LinksRouter = lazy(async () => ({
  default: (await import('components/routing/LinksRouter')).LinksRouter,
}));

const SettingsRouter = lazy(async () => ({
  default: (await import('components/routing/SettingsRouter')).SettingsRouter,
}));

const SchemasRouter = lazy(async () => ({
  default: (await import('components/routing/SchemasRouter')).SchemasRouter,
}));
const HomePage = lazy(async () => ({
  default: (await import('components/pages/HomePage')).HomePage,
}));
const DashboardTabsRouter = lazy(async () => ({
  default: (await import('components/routing/DashboardTabsRouter'))
    .DashboardTabsRouter,
}));

export const Routes = () => {
  const isBeta = useIsBeta();
  return (
    <Switch>
      <Route path="/login">
        <DefaultLoginRedirect />
      </Route>
      <Route path={USER_LOGIN_REDIRECT}>
        <LoggedInPage />
      </Route>

      <Route path="/invite/:inviteCode" component={UserInviteLoginPage} />

      <Route
        path="/invite/user-new-workspace/:inviteCode"
        component={NewWorkspacePage}
      />

      <Route path="/add-new-workspace" component={NewWorkspacePage} />

      <DecodedParamsRoute
        path="/w/:workspaceId/invite/:inviteCode"
        component={WorkspaceInviteLoginPage}
      />

      <DecodedParamsRoute
        path="/w/:workspaceId/invite/:inviteCode/accept"
        component={AcceptInvitePage}
      />

      <Route path="/oauth/increase/redirect">
        <IncreaseWorkspaceURLRedirect />
      </Route>

      <ProtectedRoute path="/w/:workspaceId/oauth/increase">
        <IncreaseOnboardingRedirect />
      </ProtectedRoute>

      <Route path="/oauth/stripe/redirect">
        <StripeWorkspaceURLRedirect />
      </Route>

      <ProtectedRoute path="/w/:workspaceId/oauth/stripe">
        <StripeOnboardingRedirect />
      </ProtectedRoute>
      {isBeta ? (
        <ProtectedRoute path="/w/:workspaceId/:rest*">
          {({ workspaceId }) => (
            <ColorModeProvider>
              <DashboardTabsRouter
                workspaceId={workspaceId}
                base={makePath(['w', workspaceId])}
              />
            </ColorModeProvider>
          )}
        </ProtectedRoute>
      ) : (
        <></>
      )}
      <ProtectedRoute path="/w/:workspaceId/ledgers/:rest*">
        {({ workspaceId }) => (
          <SidebarLayoutSuspense activePath="ledgers" loadingText="Ledger">
            <LedgersRouter base={makePath(['w', workspaceId, 'ledgers'])} />
          </SidebarLayoutSuspense>
        )}
      </ProtectedRoute>
      <ProtectedRoute path="/w/:workspaceId/home">
        {({ workspaceId }) => <HomePage />}
      </ProtectedRoute>

      <ProtectedRoute path="/w/:workspaceId/s/:rest*">
        {({ workspaceId }) => (
          <SidebarLayoutSuspense activePath="settings" loadingText="Workspace">
            <SettingsRouter base={makePath(['w', workspaceId, 's'])} />
          </SidebarLayoutSuspense>
        )}
      </ProtectedRoute>

      <ProtectedRoute path="/w/:workspaceId/links/:rest*">
        {({ workspaceId }) => (
          <SidebarLayoutSuspense activePath="links" loadingText="Links">
            <LinksRouter base={makePath(['w', workspaceId, 'links'])} />
          </SidebarLayoutSuspense>
        )}
      </ProtectedRoute>

      <ProtectedRoute path="/w/:workspaceId/explorer/:rest*">
        <SidebarLayoutSuspense activePath="apiExplorer" loadingText="Explorer">
          <ApiExplorerPage />
        </SidebarLayoutSuspense>
      </ProtectedRoute>

      <ProtectedRoute path="/w/:workspaceId/schemas/:rest*">
        {({ workspaceId }) => (
          <SidebarLayoutSuspense activePath="schemas" loadingText="Schemas">
            <SchemasRouter base={makePath(['w', workspaceId, 'schemas'])} />
          </SidebarLayoutSuspense>
        )}
      </ProtectedRoute>

      {/* For any other (invalid) workspace route, redirect to default tab */}
      <ProtectedRoute
        path="/w/:workspaceId/:rest*"
        component={DefaultTabRedirect}
      />

      <Route path="/garbage/income-statement">
        <FragmentIncIncomeStatementPage />
      </Route>

      <Route path="/garbage/balance-sheet">
        <FragmentIncBalanceSheetPage />
      </Route>

      {/* Don't love this use of render props but not sure if it's worth it to move */}
      <Route path="/go/:slug*">
        {({ slug }) => {
          const redirect =
            {
              schemas: 'schemas',
              ledgers: 'ledgers',
              explorer: 'explorer',
              links: 'links',
              's/workspace': 's/workspace',
              's/api-clients': 's/api-clients',
              's/members': 's/members',
              's/data-exports': 's/data-exports',
              's/billing': 's/billing',
            }[slug] ?? slug;
          putLocalStorage('post_login_redirect', redirect);
          return <DefaultRedirect />;
        }}
      </Route>

      <Route>
        <DefaultRedirect />
      </Route>
    </Switch>
  );
};
