/* eslint-disable no-nested-ternary */
import React, { type PropsWithChildren } from 'react';
import { Link as WouterLink } from 'wouter';

import type { KeyedRequired } from '@fragment/types';
import { List, ListItem, Marker } from '@fragment/ui/src/components/List';
import { Text } from '@fragment/ui/src/components/Text';

import { useIsBeta } from '../../hooks/useIsBeta';

type NavTreeRenderer = {
  props: Omit<Parameters<typeof Link>[0], 'depth'>;
  onHover?: () => void;
};

type LeafNav = NavTreeRenderer & {
  subNav?: never;
  visibleOverride?: never;
};

type SubTreeNav = NavTreeRenderer & {
  subNav: NavTree;
  // The ledgers and ledgers.accounts nav items should not show their subtree
  // when active since we don't know how to render the subnav tree URLs based
  // on our current route. This is because they render list pages which don't need
  // ledgerId or accountId to render.
  visibleOverride?: boolean;
};

export type NavTree<T extends string = string> = Record<
  T,
  LeafNav | SubTreeNav
>;

const NAV_TYPE_DEPTH_0 = ['numeric', 'ordered'] as const;
const NAV_TYPE_DEPTH_1 = ['upper-alphabetic', 'ordered'] as const;
const NAV_TYPE_DEPTH_2_AND_GREATER = ['bullet', 'unordered'] as const;

const getNavTypeForDepth = (depth: number) => {
  switch (depth) {
    case 0:
      return NAV_TYPE_DEPTH_0;
    case 1:
      return NAV_TYPE_DEPTH_1;
    default:
      // The mock only has Dashboard nav going to depth 3. We have not discussed what
      // the pattern should be beyond that so opting for this default in those cases.
      return NAV_TYPE_DEPTH_2_AND_GREATER;
  }
};

type LinkProps = KeyedRequired<
  Omit<JSX.IntrinsicElements['a'], 'color'>,
  'href'
> & {
  active?: boolean;
  depth: number;
};

const Link = ({
  href,
  depth,
  active,
  ...props
}: PropsWithChildren<LinkProps>) => (
  <WouterLink href={href}>
    <Text
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      as="a"
      color={
        active || depth === 0 ? 'text-main' : 'text-main-600 hover:text-main'
      }
      group
    />
  </WouterLink>
);

type Props = {
  navTree: NavTree;
  depth?: number;
};

export const SideNav = ({ navTree, depth = 0 }: Props) => {
  const [markerType, listType] = getNavTypeForDepth(depth);
  const isBeta = useIsBeta();
  return (
    <List
      type={listType}
      margin={
        depth === 0
          ? undefined
          : isBeta
          ? 'ml-[1ch] mb-[1.25ch]'
          : 'ml-[1em] mb-[1.25em]'
      }
    >
      {Object.entries(navTree).map(
        (
          [
            id,
            {
              subNav,
              visibleOverride,
              onHover,
              props: { children: linkChildren, ...otherLinkProps },
            },
          ],
          index
        ) => (
          <ListItem key={id}>
            <Link
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...otherLinkProps}
              data-testid="nav-item"
              depth={depth}
              onMouseOver={onHover}
            >
              <Marker
                type={markerType}
                ordinal={index}
                margin={isBeta ? 'mr-[0.5ch]' : 'mr-[0.5em]'}
                color={
                  otherLinkProps.active
                    ? 'text-main'
                    : 'text-main-600 group-hover:text-main'
                }
              />
              {linkChildren}
            </Link>
            {(visibleOverride ?? otherLinkProps.active) && subNav && (
              <SideNav navTree={subNav} depth={depth + 1} />
            )}
          </ListItem>
        )
      )}
    </List>
  );
};
