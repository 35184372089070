/* eslint-disable react/no-unused-prop-types */ // For some reason the linter thinks SubPageTypes is a PropTypes declaration
import React, { lazy } from 'react';
import { type RouterProps, Redirect, Router, Switch } from 'wouter';

import { DecodedParamsRoute } from './DecodedParamsRoute';
import { DecodedParamsRouteSuspense } from './DecodedParamsRouteSuspense';

const LedgersListPage = lazy(async () => ({
  default: (await import('components/pages/LedgersListPage')).LedgersListPage,
}));

const ChildAccountListPage = lazy(async () => ({
  default: (await import('components/pages/ChildAccountListPage'))
    .ChildAccountListPage,
}));

const LinesListPage = lazy(async () => ({
  default: (await import('components/pages/LinesListPage')).LinesListPage,
}));

const AccountDetailPage = lazy(async () => ({
  default: (await import('components/pages/AccountDetailPage'))
    .AccountDetailPage,
}));

const LedgerAccountPage = lazy(async () => ({
  default: (await import('components/pages/LedgerAccountPage'))
    .LedgerAccountPage,
}));

const SingleCurrencyAccountBalancesPage = lazy(async () => ({
  default: (
    await import('components/pages/balances/SingleCurrencyAccountBalancesPage')
  ).SingleCurrencyAccountBalancesPage,
}));

const MultiCurrencyAccountBalancesPage = lazy(async () => ({
  default: (
    await import('components/pages/balances/MultiCurrencyAccountBalancesPage')
  ).MultiCurrencyAccountBalancesPage,
}));

const UnreconciledPage = lazy(async () => ({
  default: (await import('components/pages/UnreconciledPage')).UnreconciledPage,
}));

const LedgerEntryListPage = lazy(async () => ({
  default: (await import('components/pages/LedgerEntryListPage'))
    .LedgerEntryListPage,
}));

const LedgerEntryDetailPage = lazy(async () => ({
  default: (await import('components/pages/LedgerEntryDetailPage'))
    .LedgerEntryDetailPage,
}));

const LedgerEntryGroupListPage = lazy(async () => ({
  default: (await import('components/pages/LedgerEntryGroupListPage'))
    .LedgerEntryGroupListPage,
}));

const LedgerEntryGroupEntryListPage = lazy(async () => ({
  default: (await import('components/pages/LedgerEntryGroupEntryListPage'))
    .LedgerEntryGroupEntryListPage,
}));

const LedgerEntryGroupBalanceListPage = lazy(async () => ({
  default: (await import('components/pages/LedgerEntryGroupBalanceListPage'))
    .LedgerEntryGroupBalanceListPage,
}));

export const LedgersRouter = ({
  base,
  hook,
  matcher,
}: Partial<RouterProps>): ReturnType<typeof Router> => (
  // Key required so router re-renders when the base route changes
  // From: https://github.com/molefrog/wouter#are-relative-routes-and-links-supported
  <Router hook={hook} matcher={matcher} base={base} key={base}>
    <Switch>
      <DecodedParamsRoute path="/">
        <LedgersListPage />
      </DecodedParamsRoute>

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts"
        activePath="ledgers.accounts"
        loadingText="Ledger"
        component={LedgerAccountPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId"
        activePath="ledgers.accounts"
        loadingText="Account"
        component={AccountDetailPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/lines"
        activePath="ledgers.accounts.lines"
        loadingText="Account"
        component={LinesListPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/lines/:lineId"
        activePath="ledgers.accounts.lines"
        loadingText="Account"
        component={LinesListPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/childaccounts"
        activePath="ledgers.accounts.childAccounts"
        loadingText="Account"
        component={ChildAccountListPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/balances"
        activePath="ledgers.accounts.balances"
        loadingText="Account"
        component={SingleCurrencyAccountBalancesPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/balances/:balanceType"
        activePath="ledgers.accounts.balances"
        loadingText="Account"
        component={MultiCurrencyAccountBalancesPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/unreconciled"
        activePath="ledgers.accounts.unreconciled"
        loadingText="Account"
        component={UnreconciledPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/accounts/:accountId/unreconciled/:txId"
        activePath="ledgers.accounts.unreconciled"
        loadingText="Account"
        component={UnreconciledPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/entries/:entryId"
        activePath="ledgers.entries"
        loadingText="Entry"
        component={LedgerEntryDetailPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/entries/:entryId/lines"
        activePath="ledgers.entries"
        loadingText="Entry"
        component={LedgerEntryDetailPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/entries/:entryId/lines/:lineId"
        activePath="ledgers.entries"
        loadingText="Entry"
        component={LedgerEntryDetailPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/entries"
        activePath="ledgers.entries"
        loadingText="Ledger"
        component={LedgerEntryListPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/groups/:groupId/entries"
        activePath="ledgers.groups.entries"
        loadingText="Group"
        component={LedgerEntryGroupEntryListPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/groups/:groupId/balances"
        activePath="ledgers.groups.balances"
        loadingText="Group"
        component={LedgerEntryGroupBalanceListPage}
      />

      <DecodedParamsRouteSuspense
        path="/:ledgerId/groups"
        activePath="ledgers.groups"
        loadingText="Ledger"
        component={LedgerEntryGroupListPage}
      />

      <DecodedParamsRoute>
        <Redirect href="/" replace />
      </DecodedParamsRoute>
    </Switch>
  </Router>
);
